$conditionSpace: 32px;
$borderSpace: 16px;
$elementHeight: 48px;
$elementMargin: 8px;

.condition-tree {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;

  .tree-item {
    position: relative;
    margin-bottom: $elementMargin;
  }

  li {
    position: relative;
  }

  ul {
    list-style: none;
  }

  ul {
    position: relative;
    padding-left: $conditionSpace;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -8px;
      left: $borderSpace;
      bottom: 17px;
      width: 0;
      border-left: 1px solid var(--border-color);
    }

    li {
      .tree-item {
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          left: -$conditionSpace + $borderSpace + 1;
          width: $conditionSpace - $borderSpace - 1;
          height: 0;
          border-top: 1px solid var(--border-color);
        }
      }
    }
  }

  >li>ul {
    &::before {
      top: -$elementMargin;
    }
  }
}
