$primary: #ffe000;

body {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  display: block;
  max-width: 100%;
}

ol {
  list-style-type: decimal;
  padding-inline-start: 40px;
  margin: 0;

  li::marker {
    text-indent: inherit !important;
  }

  > li > ol {
    list-style-type: lower-alpha;
  }
}

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

#root:not([aria-hidden="true"]) {
  .notistack-SnackbarContainer {
    z-index: 1099 !important;
  }
}

.wallet-adapter-modal {
  z-index: 1300 !important;
}

.App {
  .wallet-adapter-button {
    background-color: $primary;
    color: #000;
    padding: 0 16px;
    height: 38px;
    line-height: 38px;
    margin: auto;
    &.hide-wallet-icon {
      .wallet-adapter-button-start-icon {
        display: none;
      }
    }

    &:not([disabled]):hover {
      background-color: rgba(255, 224, 0, 0.85);
    }

    &.wallet-multi-gradient {
      background: linear-gradient(
        90deg,
        $primary 0%,
        #ff9962 50.52%,
        #947dff 100%
      );

      &:disabled {
        color: #000;
      }
    }
  }
}
@keyframes pulsing {
  0% {
    box-shadow: 0 0 0 0 rgb(0 255 12 / 50%);
  }
  70% {
    box-shadow: 0 0 0 8px rgb(0 255 12 / 0%);
  }
  100% {
    box-shadow: 0 0 0 8px rgb(0 255 12 / 0%);
  }
}
